import React, { useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Summary from 'src/features/summary/summary-two'
import GameDayJosef from 'src/features/countdown/game-day-josef'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import useSummaryData from 'src/features/summary/use-summary-data'
import LoadingSpinner from 'src/common/components/loading-spinner'

type LiveUpdatesProps = {
  matchDate: string | Date
}
const LiveUpdates = ({ matchDate }: LiveUpdatesProps) => {
  const props = useMotionNavOffsetStyle()
  const { data, isLoading } = useSummaryData(matchDate)
  console.log('LiveUpdates -> data', data)
  //TODO: fallback to handle error of not finding match

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setMatchDate(event.target.value as string)
  // }

  if (!isLoading && data) {
    return <Summary data={data} isLive />
  }

  return (
    <>
      <motion.div {...props}>
        {!(isLoading || data) && (
          <h2 style={{ textAlign: 'center' }}>Couldn't find match...</h2>
        )}
      </motion.div>
      <LoadingSpinner isLoading={isLoading} isFullPage />
    </>
  )
}

export default LiveUpdates
