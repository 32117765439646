import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { SizeMe } from 'react-sizeme'
import styled from 'styled-components'
import color from 'color'
import Canvas from './canvas'
import { ScrapedMatch } from 'plugins/gatsby-source-scrape-schedule/scrape-schedule'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

export type Match = ScrapedMatch & {
  id: string
  detailedBroadcast: null | { tv: string; streaming: string }
  completed: boolean
}

type CountdownProps = {
  matchDate?: Date
  areNoFutureMatches: boolean
  prevMatchDate: Date
  // checkAfterMatchStart(): void
}
const Countdown = ({
  matchDate,
  areNoFutureMatches,
  prevMatchDate,
}: CountdownProps) => {
  const props = useMotionNavOffsetStyle({}, { dodge: 'none' })
  // const props = useMotionNavOffsetStyle()
  const [isMounted, setIsMounted] = useState(false)

  //Workaround to canvas not always rendering properly on initial page load
  //in the built version of the app. Just keeps canvas away until mount.
  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <>
      <SizeMe>
        {({ size }) => (
          <Container
            {...props}
            style={{
              // Hack to make 1em = 1vw, but have the fake 'vw' exclude the sidebar offset
              fontSize: size.width
                ? `${size.width / 100}px`
                : 'calc(100vw / 100)',
            }}
          >
            <LabelContainer>
              <LabelWrapper>
                <Label>days</Label>
              </LabelWrapper>
              <LabelWrapper>
                <Label>hours</Label>
              </LabelWrapper>
              <LabelWrapper>
                <Label>minutes</Label>
              </LabelWrapper>
              <LabelWrapper>
                <Label>seconds</Label>
              </LabelWrapper>
              <LabelWrapper>
                <Label>100th/s</Label>
              </LabelWrapper>
            </LabelContainer>
            {isMounted && size.width != null && (
              <Canvas
                matchDate={matchDate}
                areNoFutureMatches={areNoFutureMatches}
                prevMatchDate={prevMatchDate}
                sidePaddingVW={sidePaddingVW}
                // checkAfterMatchStart={checkAfterMatchStart}
                containerWidth={size.width}
              />
            )}
          </Container>
        )}
      </SizeMe>
    </>
  )
}

export default Countdown

/*
? Note on the following styles:

Basically, a lot of styles depend on the countdown container width,
which is generally 100vw, but shrinks to accommodate the sidebar.
So the font-size hack seemed easiest to style them using fake vw units that
also shrink to accommodate sidebar--i.e., magic shrinking vw units.
So I just had to change vw to em in all other styles, and now everything
automatically adjusts to the sidebar. Presto!

This also helps a lot with the transitions. Just transition the margin and
font size for the outer countainer, and all of the styles that would have
manually had to have transitions applied to them now also happen automatically.
*/

const sidePaddingVW = 1
const Container = styled(motion.div)`
  /* will-change: font-size; */
  padding: ${sidePaddingVW}em;
  background-color: ${(p) => p.theme.palette.primary.main}bb;
  /* background-color: ${(p) =>
    color(p.theme.palette.primary.main)
      .fade(0.4)
      .rgb()
      .string()}; */
  /* backdrop-filter: blur(12px); */
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div:nth-child(even) {
    color: ${(p) => p.theme.palette.background.default};
  }
  > div:nth-child(odd) {
    color: ${(p) => p.theme.palette.secondary.main};
  }
`
const LabelWrapper = styled.div`
  width: 16em;
  text-align: left;
`
const Label = styled.div`
  padding-left: 0.3ch;
  margin-bottom: 0.35ch;
  box-shadow: 0 0.35ch 0 0;
  font-size: 2.6em;
  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 1.95em;
  }
`
