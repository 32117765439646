import React, { useEffect, useContext } from 'react'
import useNextMatchData from 'src/common/hooks/use-next-match-data'
// import { getTimePieces } from 'src/common/utils'
import { getClubInfo } from 'src/common/clubs'
import Countdown from 'src/features/countdown'
// import GameDayJosef from 'src/features/countdown/game-day-josef'
import Matchup from 'src/features/countdown/matchup'
// import GameTime from 'src/features/countdown/game-time'
import SEO from 'src/common/seo'
import { PageProps } from 'gatsby'
import { LayoutContext } from 'src/features/layout'
// import WaitingJosef from 'src/features/countdown/waiting-josef'
import LiveUpdates from 'src/features/summary/live-updates'
// import useMatchData from 'src/common/hooks/use-match-data'
import { IsLiveContext } from 'src/features/live/live-context'
// import NoFutureMatches from 'src/features/no-future-matches'
import CenterContent from 'src/features/countdown/center-content'
// import { useTypedSelector } from 'src/reducers'

//TODO: refactor this to be more sensible for no future match date

const IndexPage = ({ location }: PageProps) => {
  // console.log('IndexPage -> reduxState', reduxState)
  // const dispatch = useDispatch()
  const { setLocation } = useContext(LayoutContext)
  const { isLive, isMatchDay } = useContext(IsLiveContext)
  const {
    matchDate,
    nextMatch,
    areNoFutureMatches,
    prevMatchDate,
  } = useNextMatchData()

  //! Manual debugging placeholder
  // const realMatchDate = new Date('2020-07-11T20:00:00')

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const seoTitle = nextMatch
    ? `${getClubInfo(nextMatch?.homeTeam).abbrev} vs ${
        getClubInfo(nextMatch?.awayTeam).abbrev
      }`
    : 'No Upcoming Matches'

  return (
    <>
      <SEO title={seoTitle} />
      <div style={{ overflow: 'hidden' }}>
        <CenterContent
          isLive={isLive}
          isMatchDay={isMatchDay}
          // areNoFutureMatches={areNoFutureMatches}
        />
        {(isLive && matchDate) || false ? (
          //! Manual debugging placeholder
          // <LiveUpdates matchDate={realMatchDate} />
          <LiveUpdates matchDate={matchDate} />
        ) : (
          <>
            <Countdown
              matchDate={matchDate}
              areNoFutureMatches={areNoFutureMatches}
              prevMatchDate={prevMatchDate}
            />

            <Matchup match={nextMatch} />
          </>
        )}
      </div>
    </>
  )
}

export default IndexPage
