import React from 'react'
// import NoFutureMatches from 'src/features/no-future-matches'
import WaitingJosef from 'src/features/countdown/waiting-josef'
import GameDayJosef from './game-day-josef'

type CenterContentProps = {
  isLive: boolean
  isMatchDay: boolean
  // areNoFutureMatches: boolean
}
const CenterContent = ({
  isLive,
  isMatchDay,
}: // areNoFutureMatches,
CenterContentProps) => {
  // if (areNoFutureMatches) return <NoFutureMatches />
  if (isMatchDay || isLive)
    return <GameDayJosef isLive={isLive} isMatchDay={isMatchDay} />
  return <WaitingJosef />
}

export default CenterContent
