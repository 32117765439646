// import { ResultDataQuery } from '../../../types/graphql-types'
import firebase from 'gatsby-plugin-firebase'
import { useState, useEffect, useRef } from 'react'
import { SummaryData } from './types'

const useSummaryData = (matchDate: Date | string) => {
  const [isLoading, setIsLoading] = useState(true)

  //The idea with the ref is to not trigger useEffects with change in matchDate
  //object, but only when actual date changes
  const dateRef = useRef(createDateObj(matchDate))
  if (new Date(matchDate).valueOf() !== dateRef.current.valueOf()) {
    dateRef.current = createDateObj(matchDate)
  }
  const date = dateRef.current

  const [data, setData] = useState<SummaryData | undefined>()

  //using +/- 12 hour window just in case start time changes at last second
  const startDateRange = new Date(date.valueOf() - 1000 * 60 * 60 * 12)
  const endDateRange = new Date(date.valueOf() + 1000 * 60 * 60 * 12)

  //live updates from firestore
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`/matches`)
      // .where('date', '==', date)
      .where('date', '>=', startDateRange)
      .where('date', '<=', endDateRange)
      //just in case
      .limit(1)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.docs[0]?.data()
          if (data) {
            setData({
              ...(data as SummaryData),
              date: new Date(data.date.seconds * 1000),
            })
          } else {
            setData(undefined)
          }
          setIsLoading(false)
        },
        (err) => console.error(err)
      )
    return unsubscribe
  }, [date])

  return { data, isLoading }
}

export default useSummaryData

const createDateObj = (matchDate: Date | string) =>
  typeof matchDate === 'string' ? new Date(matchDate) : matchDate
