import React, { useEffect, useRef, useContext } from 'react'
import useTheme from 'src/common/hooks/use-theme'
import { getTimePieces } from 'src/common/utils'
import { IsLiveContext } from 'src/features/live/live-context'
// import useNextMatchData from 'src/common/hooks/use-next-match-data'

type CanvasProps = {
  matchDate?: Date
  areNoFutureMatches: boolean
  prevMatchDate: Date
  sidePaddingVW: number
  // checkAfterMatchStart(): void
  containerWidth: number
}
const Canvas = ({
  matchDate,
  sidePaddingVW,
  areNoFutureMatches,
  prevMatchDate,
  // checkAfterMatchStart,
  containerWidth,
}: CanvasProps) => {
  // const { matchDate, nextMatch } = useNextMatchData()
  const { checkIsLive } = useContext(IsLiveContext)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const theme = useTheme()
  const red = theme.palette.secondary.main
  const black = theme.palette.background.default

  //canvas width needs to accommodate for side padding
  const canvasWidth =
    containerWidth - containerWidth * (sidePaddingVW / 100) * 2
  const fonSizePx = 0.13 * containerWidth
  //0.85 is 'line height' for the font, basically
  const canvasHeight = fonSizePx * 0.85
  //0.16 is just a number that looks nice for segment widths
  //Note this corresponds to a css property for the labels
  const segmentWidth = containerWidth * 0.16
  const numOfSegments = 5

  //TODO: explain why this is the correct equation
  const segmentOffset = (canvasWidth - segmentWidth) / (numOfSegments - 1)
  const calcSegmentPosition = (segmentIndex: number) =>
    segmentIndex * segmentOffset

  useEffect(() => {
    //time in ms between re-painting the canvas
    const timeout = 43 //43 is 'best looking' interval
    const ctx = setupCanvas(canvasRef.current!)!
    ctx.font = `bold ${fonSizePx}px Roboto Mono`

    const paintCanvas = () => {
      const { days, hours, minutes, seconds, centiseconds } = getTimePieces(
        areNoFutureMatches,
        matchDate ?? prevMatchDate
      )

      ctx.clearRect(0, 0, canvasWidth, canvasHeight)

      //odd segments
      ctx.fillStyle = red
      ctx.fillText(
        days,
        calcSegmentPosition(0),
        canvasHeight * 0.95,
        segmentWidth
      )
      ctx.fillText(
        minutes,
        calcSegmentPosition(2),
        canvasHeight * 0.95,
        segmentWidth
      )
      ctx.fillText(
        centiseconds,
        calcSegmentPosition(4),
        canvasHeight * 0.95,
        segmentWidth
      )

      //even segments
      ctx.fillStyle = black
      ctx.fillText(
        hours,
        calcSegmentPosition(1),
        canvasHeight * 0.95,
        segmentWidth
      )
      ctx.fillText(
        seconds,
        calcSegmentPosition(3),
        canvasHeight * 0.95,
        segmentWidth
      )
    }

    //call once to immediately paint before interval starts
    checkIsLive()
    paintCanvas()
    const interval =
      // matchDate &&
      setInterval(() => {
        checkIsLive()
        paintCanvas()
      }, timeout)

    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasWidth])
  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
      //necessary for canvas DPR scaling
      style={{ width: canvasWidth }}
    />
  )
}

export default Canvas

//taken from this lovely article:
//www.html5rocks.com/en/tutorials/canvas/hidpi/
const setupCanvas = (canvas: HTMLCanvasElement) => {
  // Get the device pixel ratio, falling back to 1.
  const dpr = window.devicePixelRatio || 1
  // Get the size of the canvas in CSS pixels.
  const rect = canvas.getBoundingClientRect()
  // Give the canvas pixel dimensions of their CSS
  // size * the device pixel ratio.
  canvas.width = rect.width * dpr
  canvas.height = rect.height * dpr
  const ctx = canvas.getContext('2d')
  // Scale all drawing operations by the dpr, so you
  // don't have to worry about the difference.
  ctx?.scale(dpr, dpr)
  return ctx
}
