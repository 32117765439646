import { useStaticQuery, graphql } from 'gatsby'
import { GameDayJosefsQuery } from 'types/graphql-types'

//TODO: fragments, probably many other things

//these should correspond to the appropriate filenames, obvs
export type JosefOptions = 'Listening' | 'Looking' | 'Yelling' | 'Smoking'
// export type JosefOptions = string

const useJosef = (isAfterScheduleTime: boolean, whichJosef: JosefOptions) => {
  const { preGame, duringGame } = useStaticQuery<GameDayJosefsQuery>(graphql`
    query GameDayJosefs {
      preGame: allFile(
        filter: {
          relativeDirectory: { eq: "josefs/pre-game" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      duringGame: allFile(
        filter: {
          relativeDirectory: { eq: "josefs/during-game" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const nodes = isAfterScheduleTime ? duringGame?.nodes : preGame?.nodes
  const josefIndex = getIndex(nodes, whichJosef)
  const josefFluidData = getFluidData(nodes, josefIndex)

  return josefFluidData
}

export default useJosef

const getIndex = (nodes: any[], whichJosef?: string) =>
  nodes.findIndex(
    (josef: any) =>
      josef.childImageSharp?.fluid?.originalName === `${whichJosef}.png`
  )

const getFluidData = (nodes: any[], i: number) =>
  nodes[i].childImageSharp?.fluid
