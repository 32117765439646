import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { SettingsContext } from 'src/features/settings/settings-context'
import useJosef from './use-josef'
import useNextMatchData from 'src/common/hooks/use-next-match-data'
import { getTimePieces } from 'src/common/utils'
// import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import { motion } from 'framer-motion'

type JosefProps = {
  isLive: boolean
  isMatchDay: boolean
}
const GameDayJosef = ({ isLive, isMatchDay }: JosefProps) => {
  const props = useMotionNavOffsetStyle(
    { alignment: 'center' },
    { dodge: 'none' }
  )
  const { matchDate, midnightTimeDelta } = useNextMatchData()
  const calcTimePercentage = () => {
    const { timeDelta } = getTimePieces(false, matchDate)
    return isLive ? 1 : (midnightTimeDelta! - timeDelta) / midnightTimeDelta!
  }
  const [timeModifier, setTimeModifier] = useState(calcTimePercentage())
  const [settings] = useContext(SettingsContext)
  const { activeJosef } = settings
  const josefFluidData = useJosef(isLive, activeJosef)

  //update Josef's timeModifer (for styling) at interval
  useEffect(() => {
    const updateTimeModifier = () => {
      // if (isLive) return
      setTimeModifier(calcTimePercentage())
    }
    const interval =
      isMatchDay && !isLive ? setInterval(updateTimeModifier, 10000) : 0
    setTimeModifier(calcTimePercentage())
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLive])

  //make sure the timeModifier is 1 when isAfterGame
  // useEffect(() => {
  //   setTimeModifier(calcTimePercentage())
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLive])

  const widthModifier = timeModifier * 2.5
  // const widthModifier = 1
  const sizes = `calc(100vw*${widthModifier})`

  //TODO: figure out type
  const propsStyle = (props as any)?.style ? (props as any).style : {}

  //*Need inline styles here to avoid styled-components creating
  //*a new class every second from changing props
  return (
    <ElRey
      {...props}
      style={
        isLive
          ? {
              ...propsStyle,
              width: `${widthModifier * 100}vmin`,
            }
          : {
              ...propsStyle,
              width: `${widthModifier * 100}vmin`,
              filter: `opacity(${timeModifier})
                       brightness(${timeModifier})`,
            }
      }
    >
      <Img
        draggable={false}
        fadeIn={false}
        //♫ graphql/Img types are not my friend ♫
        fluid={{ ...(josefFluidData as any), sizes }}
      />
    </ElRey>
  )
}
export default GameDayJosef

const ElRey = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
  border-radius: 50%;
  overflow: hidden;
`
