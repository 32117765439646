import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import color from 'color'
import { getClubInfo } from 'src/common/clubs'
import useTheme from 'src/common/hooks/use-theme'
import MatchDate from './match-date'
import FluidClubLogo from '../../../common/components/team-logos/fluid-club-logo'
import { Match } from 'src/features/countdown'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import DetailedBroadcast from './detailed-broadcast'
import YoutubeAudioButton from 'src/features/youtube-audio-button'
import { fabSizePx } from 'src/common/components/fab-container'
import { SettingsContext } from 'src/features/settings/settings-context'

type MatchupProps = {
  match?: Match
}
const Matchup = ({ match }: MatchupProps) => {
  const [{ isEuroMode }] = useContext(SettingsContext)
  const props = useMotionNavOffsetStyle()
  const theme = useTheme()

  if (!match) {
    return (
      <>
        <SinceText {...props}>
          ...since the last {isEuroMode ? 'match' : 'game'}
        </SinceText>
        <MatchupWrapper {...props}>
          <NoFutureMatchesText>
            No future {isEuroMode ? 'matches' : 'games'} scheduled yet
          </NoFutureMatchesText>
        </MatchupWrapper>
        <YoutubeAudioButton
          videoId="7-9wXQpzESo"
          playerVars={{ autoplay: 0, start: 4 }}
        />
      </>
    )
  }

  const home = getClubInfo(match.homeTeam)
  const away = getClubInfo(match.awayTeam)

  const atlShadowColor = theme.palette.secondary.light
  const opponentShadowColor = theme.palette.primary.dark

  return (
    <MatchupWrapper {...props}>
      <MatchupContainer>
        {/* Home Team */}
        <Tooltip title={home.displayName} placement="top">
          <TeamContainer>
            <LogoWrapper
              shadowColor={match.isHome ? atlShadowColor : opponentShadowColor}
            >
              <FluidClubLogo clubName={home.aliases[0]} sizes={sizes} />
            </LogoWrapper>
          </TeamContainer>
        </Tooltip>

        <div style={{ position: 'relative' }}>
          <MatchDate match={match} />
          <DetailedBroadcast match={match} />
        </div>

        {/* Away Team */}
        <Tooltip title={away.displayName} placement="top">
          <TeamContainer>
            <LogoWrapper
              shadowColor={!match.isHome ? atlShadowColor : opponentShadowColor}
            >
              <FluidClubLogo clubName={away.aliases[0]} sizes={sizes} />
            </LogoWrapper>
          </TeamContainer>
        </Tooltip>

        <AbbrevContainer>
          <Abbrev>{home.abbrev}</Abbrev>
          <Abbrev>{away.abbrev}</Abbrev>
        </AbbrevContainer>
      </MatchupContainer>
    </MatchupWrapper>
  )
}

export default Matchup

const logoWidthPercent = 25
const sizes = `(max-width: 1200px) calc(100vw*${logoWidthPercent /
  100}), 1200px`

const SinceText = styled(motion.div)`
  padding-top: 0.25ch;
  padding-left: 1.5ch;
  color: ${(p) => p.theme.palette.lightGold};
`
const NoFutureMatchesText = styled.div`
  width: fit-content;
  margin: auto;
  padding-bottom: 10vmin;
  padding-bottom: ${fabSizePx}px;
  color: ${(p) => p.theme.palette.lightGold};
`
const MatchupWrapper = styled(motion.div)`
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
`
const MatchupContainer = styled.div`
  margin: auto;
  display: flex;
  font-size: 5vmin;
  width: 95vmin;
  max-width: 100%;
  padding: 3vmin;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${(p) =>
    color(p.theme.palette.primary.light)
      .lighten(0.4)
      .hex()};
  text-shadow: 0px 0px 0.5ch ${(p) => p.theme.palette.primary.dark};
`

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${logoWidthPercent}%;
  text-align: center;
`

type LogoWrapperProps = {
  shadowColor: string
}
const LogoWrapper = styled.div<LogoWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  filter: ${(p) => `drop-shadow(0px 0px 0.4ch ${p.shadowColor})`};
`
const AbbrevContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 0.7em;
  padding-top: 0.2em;
`
const Abbrev = styled.div`
  width: ${logoWidthPercent}%;
  text-align: center;
  /* font-size: 0.7em; */
`
