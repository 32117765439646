import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { WaitingJosefsQuery } from 'types/graphql-types'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import { SettingsContext } from '../settings/settings-context'

const WaitingJosef = () => {
  const [{ isEuroMode }] = useContext(SettingsContext)
  const waitingJosefFluidData = useWaitingJosef()
  const props = useMotionNavOffsetStyle()

  return (
    <OuterContainer {...props}>
      <JosefContainer>
        <JosefImg fluid={waitingJosefFluidData as any} />
        <SpeechLine />
        <SpeechBubble>
          not {isEuroMode ? 'match' : 'game'} day yet...
        </SpeechBubble>
      </JosefContainer>
    </OuterContainer>
  )
}

export default WaitingJosef

const OuterContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const sizeVmin = 15
const JosefContainer = styled.div`
  position: absolute;
  /*slightly offset to look more center */
  top: 51.5%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${sizeVmin}vmin;
  width: 100%;
  height: ${sizeVmin}vmin;
  opacity: 0.2;
  animation: 10s josefFade ease-in infinite;
  @keyframes josefFade {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    77% {
      opacity: 0.2;
    }
    85% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
`
const JosefImg = styled(BackgroundImage)`
  /* position: absolute; */
  background-size: contain;
  height: 100%;
  width: 100%;
  /* animation: 60s rotate steps(60) infinite; */
`
const SpeechBubble = styled.div`
  position: absolute;
  top: -35%;
  top: -45%;
  left: 50%;
  transform: translateX(14vmin);
  font-size: 2vmin;
  color: ${(p) => p.theme.palette.primary.main};
  box-shadow: 0 0 0 0.3vmin ${(p) => p.theme.palette.primary.dark};
  border-radius: 0.8vmin;
  padding: 0.5vmin 1vmin;
`
const SpeechLine = styled.div`
  position: absolute;
  top: -10%;
  top: -20%;
  left: 50%;
  height: 6vmin;
  width: 0.3vmin;
  transform: translateX(10vmin) rotate(55deg);
  background-color: ${(p) => p.theme.palette.primary.dark};
  opacity: 0.6;
`
const useWaitingJosef = () => {
  const { allFile } = useStaticQuery<WaitingJosefsQuery>(graphql`
    query WaitingJosefs {
      allFile(
        filter: {
          relativeDirectory: { eq: "waiting-josefs" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return allFile.nodes[0].childImageSharp?.fluid
}
