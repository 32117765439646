import { Match } from 'src/common/types'
import React, { useState, useRef } from 'react'
import isTouch from 'is-touch-device'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { FaInternetExplorer } from 'react-icons/fa'
import IconButton from '@material-ui/core/IconButton'
import TvIcon from '@material-ui/icons/Tv'
import Popover from '@material-ui/core/Popover'
import { FaIcon } from 'src/common/styles'

type DetailedBroadcastProps = {
  match: Match
}
const DetailedBroadcast = ({ match }: DetailedBroadcastProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const anchorEl = useRef<HTMLButtonElement>(null)

  if (
    (!!match.detailedBroadcast &&
      (!!match.detailedBroadcast.tv || !!match.detailedBroadcast.streaming)) ||
    !!match.broadcast
  ) {
    return (
      <BroadcastContainer
        onMouseEnter={() => !isTouch() && setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <BroadcastIconButton
          color="primary"
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          ref={anchorEl}
        >
          <StyledTvIcon />
        </BroadcastIconButton>
        <StyledPopover
          open={isOpen}
          onClose={() => setIsOpen(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          anchorEl={anchorEl.current}
        >
          <List>
            {(!!match.detailedBroadcast?.tv || !!match.broadcast) && (
              <ListItem>
                <ListItemIcon>
                  <TvIcon />
                </ListItemIcon>
                <ListItemText style={{ userSelect: 'auto' }}>
                  {match.detailedBroadcast?.tv || match.broadcast}
                </ListItemText>
              </ListItem>
            )}
            {!!match.detailedBroadcast?.streaming && (
              <ListItem>
                <ListItemIcon>
                  <FaIcon>
                    <FaInternetExplorer />
                  </FaIcon>
                </ListItemIcon>
                <ListItemText style={{ userSelect: 'auto' }}>
                  {match.detailedBroadcast.streaming}
                </ListItemText>
              </ListItem>
            )}
          </List>
        </StyledPopover>
      </BroadcastContainer>
    )
  }

  return null
}

export default DetailedBroadcast

const BroadcastContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 45%);
`
const BroadcastIconButton = styled(IconButton)`
  position: relative;
  font-size: 0.9em;
  box-shadow: 0 0 0 0.4vmin ${(p) => p.theme.palette.primary.main}cc;
  padding: 1.6vmin;
`
const StyledPopover = styled(Popover)`
  pointer-events: none;
  .MuiPopover-paper {
    pointer-events: auto;
  }
`
const StyledTvIcon = styled(TvIcon)`
  font-size: 1em;
  color: ${(p) => p.theme.palette.lightGold};
`
