import React, { useContext } from 'react'
import styled from 'styled-components'
import dateFormat from 'dateformat'
import { Match } from 'src/features/countdown'
import { SettingsContext } from 'src/features/settings/settings-context'

type MatchDateProps = {
  match: Match
}
const MatchDate = ({ match }: MatchDateProps) => {
  const [{ isEuroMode }] = useContext(SettingsContext)
  const dateStr = isEuroMode ? 'ddd\xa0d/m' : 'ddd\xa0m/d'
  const timeStr = isEuroMode ? 'HH:MM\xa0Z' : 'h:MM\xa0TT'

  return (
    <DateContainer>
      <Date>{dateFormat(match.date, dateStr)}</Date>
      <Time>{dateFormat(match.date, timeStr)}</Time>
    </DateContainer>
  )
}

export default MatchDate

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* color: ${(p) => p.theme.palette.primary.light}; */
  color: ${(p) => p.theme.palette.lightGold};
  height: 100%;
`
const Date = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.palette.lightGold};
  line-height: 1.15;
`
const Time = styled.div`
  font-size: 0.8em;
  font-weight: normal;
`
