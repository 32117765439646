import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { absCenter } from '../styles'
import useMotionNavOffsetStyle from '../hooks/use-motion-nav-offset-style'

type LoadingSpinnerProps = {
  isLoading?: boolean
  size?: number
  isFullPage?: boolean
}
const LoadingSpinner = ({
  isLoading,
  size,
  isFullPage,
}: LoadingSpinnerProps) => {
  const props = useMotionNavOffsetStyle(
    { alignment: 'center' },
    { dodge: 'dodge' }
  )
  if (isLoading || isLoading === undefined) {
    if (isFullPage) {
      return (
        <FullPageWrapper {...props}>
          <CircularProgress size={size ?? 110} />
        </FullPageWrapper>
      )
    }
    return (
      <Wrapper>
        <CircularProgress size={size ?? 48} />
      </Wrapper>
    )
  }

  return null
}

export default LoadingSpinner

const Wrapper = styled(motion.div)`
  display: flex;
`
const FullPageWrapper = styled(Wrapper)`
  position: fixed;
  ${absCenter}
`
